import './App.css';

function App() {
  return (
    <div className="App">
      <header className="header">
        Nothing to see here (yet), move along.
      </header>
    </div>
  );
}

export default App;
